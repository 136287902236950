<template>
  <div class="formAuther-container">
    <div class="form-item">
      <div class="left">
        <Input
          placeholder="请输入第一作者"
          v-model.trim="formValidate.firstAuther"
          @input="firstInput"
          @on-blur="blurAuther"
          @on-focus="handleFocus"
        />
        <span class="xing">*</span>
      </div>
      <div class="right">
        <Input
          placeholder="请输入第一作者单位"
          v-model.trim="formValidate.firstUnit"
          :disabled="unitDisabled"
          @on-blur="blurAuther"
        />
        <span class="xing">*</span>
      </div>
      <!-- 筛选框 -->
      <ul class="select-list" v-if="showSelect">
        <li
          class="select-item"
          v-for="v in firstAutherList"
          :key="v.id"
          @click="echoAuther(v)"
        >
          <span>{{ v.realname }}</span>
          <span style="width: 500px">{{ v.orgName }}</span>
          <span>{{ v.mobile }}</span>
        </li>
        <p class="icon-pager">
          <a class="pager-left" @mousedown="handlePager('turnLeft', $event)"
            >&lt;&lt;</a
          >
          <a class="pager-right" @mousedown="handlePager('turnRight', $event)"
            >&gt;&gt;</a
          >
        </p>
        <div class="none" @click="editAuther">都不是，直接添加</div>
      </ul>
    </div>
    <div class="form-item" v-for="(v, i) in formValidate.autherList" :key="i">
      <div class="left">
        <Input
          placeholder="请输入其他作者"
          v-model.trim="v.authorName"
          @input="otherInput(v)"
          @on-focus="handleFocus1(v)"
          @on-blur="blurAuther1(v)"
        />
      </div>
      <div class="right">
        <Input
          placeholder="请输入其他作者单位"
          v-model.trim="v.authorUnit"
          :disabled="v.unitDisabled1"
          @on-blur="blurAuther1(v)"
        />
      </div>
      <ul class="select-list" v-if="v.showSelect1">
        <li
          class="select-item"
          v-for="item in firstAutherList"
          :key="item.id"
          @click="echoAuther1(v, item)"
        >
          <span>{{ item.realname }}</span>
          <span>{{ item.orgName }}</span>
          <span>{{ item.mobile }}</span>
        </li>
        <p class="icon-pager">
          <a class="pager-left" @mousedown="handlePager1(v, 'turnLeft', $event)"
            >&lt;&lt;</a
          >
          <a
            class="pager-right"
            @mousedown="handlePager1(v, 'turnRight', $event)"
            >&gt;&gt;</a
          >
        </p>
        <div class="none" @click="editAuther1(v)">都不是，直接添加</div>
      </ul>
      <div
        class="plus"
        @click="subAuther(i)"
        v-if="formValidate.autherList.length - 1 !== i || formValidate.autherList.length === 8"
      >
        -
      </div>
      <div
        class="plus"
        @click="addAuther"
        v-if="formValidate.autherList.length - 1 === i && formValidate.autherList.length <= 7"
      >
        +
      </div>
    </div>
    <div class="form-item">
      <div class="left">
        <Input
          placeholder="请输入通讯作者"
          v-model.trim="formValidate.communicationAuthor"
          @input="communicationInput"
          @on-blur="blurAuther2"
          ref="blur2"
          @on-focus="handleFocus2"
        />
      </div>
      <div class="right">
        <Input
          placeholder="请输入通讯作者单位"
          v-model.trim="formValidate.communicationAuthorUnit"
          :disabled="unitDisabled2"
          @on-blur="blurAuther2"
        />
      </div>
      <ul class="select-list" v-if="showSelect2">
        <li
          class="select-item"
          v-for="v in firstAutherList"
          :key="v.id"
          @click="echoAuther2(v)"
        >
          <span>{{ v.realname }}</span>
          <span>{{ v.orgName }}</span>
          <span>{{ v.mobile }}</span>
        </li>
        <p class="icon-pager">
          <a class="pager-left" @mousedown="handlePager2('turnLeft', $event)"
            >&lt;&lt;</a
          >
          <a class="pager-right" @mousedown="handlePager2('turnRight', $event)"
            >&gt;&gt;</a
          >
        </p>
        <div class="none" @click="editAuther2">都不是，直接添加</div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formValidate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      flag: false,
      showSelect: false,
      showSelect2: false,
      unitDisabled: true, // 禁用作者单位
      unitDisabled2: true, // 禁用作者单位
      firstAutherList: [], //作者列表
      unitId: "",
      unitId2: "",
      id2: "",
      id: "",
      // 分页
      curPage: 1,
      total: 0,
      limit: 0,
    };
  },
  computed: {
    allPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    handlePager1(v, type, e) {
      e.preventDefault();
      if (type === "turnLeft") {
        this.curPage--;
      } else {
        this.curPage++;
      }
      if (this.curPage < 1) {
        this.curPage = 1;
      }
      if (this.curPage > this.allPage) {
        this.curPage = this.allPage;
      }
      this.findAuthorInfoByNameAndCompany({
        name: v.authorName,
        pageNo: this.curPage,
        pageSize: 5,
      });
    },
    editAuther1(v) {
      v.unitDisabled1 = false;
      v.showSelect1 = false;
      v.authorUnitId = null;
      v.authorId = null;
      // this.formValidate.articleAuthorList.forEach((item) => {
      //   item.authorUnitId = null;
      //   item.authorId = null;
      // });
    },
    echoAuther1(v, item) {
      v.authorName = item.realname;
      v.authorUnit = item.orgName;
      v.authorUnitId = item.orgId;
      v.authorId = item.id;
      v.showSelect1 = false;
      v.unitDisabled1 = true;
      this.$emit("autherInfo1", this.formValidate.autherList);
    },
    blurAuther1(v) {
      setTimeout(() => {
        v.showSelect1 = false;
      }, 100);
      this.$emit("autherInfo1", this.formValidate.autherList);
    },
    handleFocus2() {
      if (this.formValidate.communicationAuthor === "") return;
      this.findAuthorInfoByNameAndCompany({
        name: this.formValidate.communicationAuthor,
        pageNo: 1,
        pageSize: 5,
      });
      this.showSelect = false;
      this.formValidate.autherList.forEach((item) => {
        return (item.showSelect1 = false);
      });
      this.showSelect2 = true;
    },
    handleFocus1(v) {
      if (v.authorName === "") return;
      this.findAuthorInfoByNameAndCompany({
        name: v.authorName,
        pageNo: 1,
        pageSize: 5,
      });
      this.formValidate.autherList.forEach((item) => {
        return (item.showSelect1 = false);
      });
      this.showSelect = false;
      v.showSelect1 = true;
      this.showSelect2 = false;
    },
    handleFocus() {
      if (this.formValidate.firstAuther === "") return;
      this.findAuthorInfoByNameAndCompany({
        name: this.formValidate.firstAuther,
        pageNo: 1,
        pageSize: 5,
      });
      this.showSelect = true;
      this.formValidate.autherList.forEach((item) => {
        return (item.showSelect1 = false);
      });
      this.showSelect2 = false;
    },
    otherInput(v) {
      if (v.authorName === "") {
        v.showSelect1 = false;
      } else {
        v.showSelect1 = true;
        this.findAuthorInfoByNameAndCompany({
          name: v.authorName,
          pageNo: 1,
          pageSize: 5,
        }); // 查询文章发布作者
      }
    },
    /**
     * 通讯作者
     */
    editAuther2() {
      this.unitDisabled2 = false;
      this.showSelect2 = false;
      this.unitId2 = null;
      this.id2 = null;
    },
    handlePager2(type, e) {
      e.preventDefault();
      if (type === "turnLeft") {
        this.curPage--;
      } else {
        this.curPage++;
      }
      if (this.curPage < 1) {
        this.curPage = 1;
      }
      if (this.curPage > this.allPage) {
        this.curPage = this.allPage;
      }
      this.findAuthorInfoByNameAndCompany({
        name: this.formValidate.communicationAuthor,
        pageNo: this.curPage,
        pageSize: 5,
      });
    },
    echoAuther2(item) {
      this.formValidate.communicationAuthor = item.realname;
      this.formValidate.communicationAuthorUnit = item.orgName;
      this.unitId2 = item.orgId;
      this.id2 = item.id;
      this.showSelect2 = false;
      this.unitDisabled2 = true;
      this.$emit(
        "autherInfo2",
        this.formValidate.communicationAuthor,
        this.formValidate.communicationAuthorUnit,
        item.orgId,
        item.id
      );
    },
    blurAuther2() {
      setTimeout(() => {
        this.showSelect2 = false;
      }, 100);
      this.$emit(
        "autherInfo2",
        this.formValidate.communicationAuthor,
        this.formValidate.communicationAuthorUnit,
        this.unitId2,
        this.id2
      );
    },
    communicationInput() {
      if (this.formValidate.communicationAuthor === "") {
        this.showSelect2 = false;
      } else {
        this.showSelect2 = true;
        this.findAuthorInfoByNameAndCompany({
          name: this.formValidate.communicationAuthor,
          pageNo: 1,
          pageSize: 5,
        }); // 查询文章发布作者
      }
    },
    /**
     * 第一作者
     */
    handlePager(type, e) {
      e.preventDefault();
      if (type === "turnLeft") {
        this.curPage--;
      } else {
        this.curPage++;
      }
      if (this.curPage < 1) {
        this.curPage = 1;
      }
      if (this.curPage > this.allPage) {
        this.curPage = this.allPage;
      }
      this.findAuthorInfoByNameAndCompany({
        name: this.formValidate.firstAuther,
        pageNo: this.curPage,
        pageSize: 5,
      });
    },
    blurAuther() {
      setTimeout(() => {
        this.showSelect = false;
      }, 100);
      this.$emit(
        "autherInfo",
        this.formValidate.firstAuther,
        this.formValidate.firstUnit,
        this.unitId,
        this.id
      );
    },
    editAuther() {
      this.unitDisabled = false;
      this.showSelect = false;
      this.unitId = null;
      this.id = null;
    },
    // 回显
    echoAuther(item) {
      this.formValidate.firstAuther = item.realname;
      this.formValidate.firstUnit = item.orgName;
      this.unitId = item.orgId;
      this.id = item.id;
      this.showSelect = false;
      this.unitDisabled = true;
      this.$emit(
        "autherInfo",
        this.formValidate.firstAuther,
        this.formValidate.firstUnit,
        item.orgId,
        item.id
      );
    },
    firstInput() {
      if (this.formValidate.firstAuther === "") {
        this.showSelect = false;
      } else {
        this.showSelect = true;
        this.findAuthorInfoByNameAndCompany({
          name: this.formValidate.firstAuther,
          pageNo: 1,
          pageSize: 5,
        }); // 查询文章发布作者
      }
    },
    // 查询文章发布作者
    async findAuthorInfoByNameAndCompany(data) {
      const resp = await this.$apis.formServe.findAuthorInfoByNameAndCompany(
        data
      );
      this.firstAutherList = resp.data.list;
      this.total = +resp.data.total;
      this.limit = +resp.data.pageSize;
    },
    // 添加其他作者
    addAuther() {
      // if (this.formValidate.autherList.length > 7) {
      //   this.$Message.warning('最多填写8个其他作者');
      //   return
      // }
      this.formValidate.autherList.push({
        authorName: "",
        authorUnit: "",
        showSelect1: false,
        unitDisabled1: true,
        authorUnitId: "",
        authorId: "",
      });
    },
    subAuther(index) {
      this.formValidate.autherList.splice(index,1)
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-input[disabled],
fieldset[disabled] .ivu-input {
  opacity: 0.7;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.form-item {
  width: 100%;
  min-height: 85px;
  padding: 25px 0;
  border-bottom: none;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  color: #999;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
  .plus {
    margin-left: 10px;
    width: 16px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    background: #FFFFFF;
    border: 1px dotted #00A4FF;
    border-radius: 3px;
    font-size: 10px;
    color: #00A4FF;
    cursor: pointer;
  }
  .icon-pager {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: inline-block;
      width: 50px;
      text-align: center;
      user-select: none;
      cursor: pointer;
      color: #999;
      &:active {
        color: #dcdcdc;
      }
    }
  }
  .select-item,
  .none {
    display: flex;
    justify-content: space-around;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    &:hover {
      background-color: rgb(212, 209, 209);
    }
    span {
      display: inline-block;
      width: 130px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .select-list {
    position: absolute;
    left: 0;
    top: 88px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    z-index: 1;
  }
  .title {
    width: 900px;
    height: 38px;
    line-height: 38px;
    position: relative;
    ::v-deep input {
      font-size: 36px;
    }
    .right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .xing {
    width: 30px;
    color: red;
    text-align: center;
  }
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    border-right: 1px solid #dcdcdc;
    width: 355px;
  }
  .right {
    margin-left: 10px;
    width: 600px;
  }
  .year,
  .center,
  .stage {
    display: flex;
    align-items: center;
  }
  .year {
    width: 299px;
    border-right: 1px solid #dcdcdc;
  }
  .center {
    width: 323px;
    border-right: 1px solid #dcdcdc;
  }
  .stage {
    width: 374px;
  }
  .checkbox {
    width: 970px;
    display: flex;
    padding-left: 7px;
    label {
      margin-right: 20px;
    }
  }
  .upload {
    display: flex;
    padding-left: 7px;
    align-items: center;
    label {
      margin-right: 20px;
    }
  }
}
</style>
